// colors
$text: #334144;
$white: #ffffff;
$black: #000000;
$orange: #EAA79E;
$grey: #949EA0;
$light-grey: #F5F6F7;
$grey-second: #D4D8D9;

// fonts
$main-font-family: 'Source Sans Pro', sans-serif;

// backgrounds
$main-bacground: #ffffff;
$close-btn-background: #333333;
$background-orange: #EAA79E;

// borders
$border-radius: 3px;
$default-border: 1px solid #DFE5EA;