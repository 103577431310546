@import './variables.scss';

.loader-block-scroll {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-size: cover;
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -ms-filter: blur(4px);
  -o-filter: blur(4px);
  filter: blur(4px);
  pointer-events: none;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

.default-date-picker {
  border: none;
  width: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 15px;
  box-sizing: border-box;
  height: 35px;
  border-radius: 4px;
}

.default-date-picker-with-icon {
  padding-left: 50px;
}

.default-date-picker-disabled {
  color: #545454;
  background: rgb(241, 244, 247) !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
